<template>
  <fragment>
    <v-app-bar
      color="primary"
      dark
      fixed
      elevation="2"
      :style="`${$vuetify.breakpoint.mdAndUp && 'padding-left: 256px;'}`"
    >
      <v-btn text icon @click.prevent="closeShowMenu">
        <v-icon v-if="drawer" size="30">{{ $vuetify.breakpoint.smAndUp ? "mdi-chevron-left" : "mdi-menu" }}</v-icon>
        <v-icon v-else size="30">{{ $vuetify.breakpoint.smAndUp ? "mdi-chevron-right" : "mdi-menu" }}</v-icon>
      </v-btn>
      <v-toolbar-title class="d-flex align-center">
        <img v-if="$store.getters.getLogoClaro" :src="$store.getters.getLogoClaro" width="45" height="45" class="mb-0" />
        <span class="mt-2 font-weight-bold ml-2" v-if="$store.getters.getLogoComTexto">{{$store.getters.getAppName}}</span>
      </v-toolbar-title>
      <cronometro ref="cronometro" />
      <v-spacer></v-spacer>
      <!-- <v-menu v-if="$route.name == 'areaLogada.lead'" right bottom transition="scroll-y-transition">
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-badge v-if="countAlertaLeads" color="#ffb61e" overlap :content="countAlertaLeads">
              <v-icon size="30">mdi-bell</v-icon>
            </v-badge>
            <v-icon v-else size="30">mdi-bell</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item v-if="!countAlertaLeads">
            <v-list-item-title>Você não possui alertas no momento.</v-list-item-title>
          </v-list-item>
          <v-list-item v-else>
            <v-list-item-title>Você possui {{ countAlertaLeads }} {{ countAlertaLeads == 1 ? "alerta" : "alertas" }} no momento.</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu> -->
      <v-menu right bottom transition="scroll-y-transition">
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-badge
              :color="notificacoesAlertas > 0
                ? 'red'
                : 'transparent'
              "
              overlap
              :content="notificacoesAlertas > 0
                  ? notificacoesAlertas
                  : ''
              "
            >
              <v-icon size="30">mdi-bell</v-icon>
            </v-badge>
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-for="(item, index) in notificacoes.slice(0,5)" :key="index" link @click.prevent="verProposta(item.internalId, item.guidProposta, item.tipo)">
            <v-sheet
            class="barra"
            :color="item.cor"
            height="100"
            width="8"
            rounded
            ></v-sheet>
            <div style="margin-left: 4px; display: flex; flex-direction: column;">
              <div>
                Proposta nº {{ item.nrProposta }}
              </div>
              <div>{{ item.descricao }}</div>
            </div>
          </v-list-item>
          <v-list-item link @click.prevent="limparNotificações(notificacoes)">
            <span style="border-bottom: 1px solid rgb(114, 114, 114);">
              Limpar notificações
            </span>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-menu right bottom transition="scroll-y-transition">
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-icon size="30">mdi-account-circle</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item link @click.prevent="$router.push({ name: 'areaLogada.meuPerfil' })">
            <v-list-item-title>Meus dados</v-list-item-title>
          </v-list-item>
          <v-list-item link @click.prevent="logoutApp()">
            <v-list-item-title >Sair</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-navigation-drawer
      fixed
      v-model="drawer"
      :permanent="$vuetify.breakpoint.mdAndUp && drawer"
      disable-resize-watcher
      v-click-outside="handleClickOutside"
    >
      <v-card tile color="primary" class="pa-2 d-flex align-center flex-column">
        <button
          class="btn-close"
          color="primary"
          @click.prevent="onClickX"
          v-if="!$vuetify.breakpoint.mdAndUp"
        >
          <v-icon color="#fff" size="20">mdi-close</v-icon>
        </button>
        <v-avatar color="#ccc" class="avatar-image mb-2 mt-4" src size="70">
          <img :src="urlFoto" :alt="user.nome" />
        </v-avatar>
        <span class="white--text subtitle-2 mb-2">{{user.nome}}</span>
        <v-btn color="primary" small dark class="mb-2" :to="{ name: 'areaLogada.meuPerfil' }">
          Ver meu perfil
        </v-btn>
      </v-card>

      <v-list nav>
        <div v-for="item in options" :key="item.title" @click="menuClick(item.link.name, false)">
        <v-list-group
          v-if="item.items"
          no-action
          color="primary"
          :data-test-id="item.title.replaceAll(' ','-').toLowerCase()"
          :prepend-icon="item.action"
        >
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item
            class="ml-n10"
            v-for="child in item.items"
            :key="child.title"
            :data-test-id="child.title.replaceAll(' ','-').toLowerCase()"
            link
            :to="child.link"
            @click="menuClick(child.link.name, true)"
          >
            <v-list-item-icon>
              <v-icon>{{ child.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>{{ child.title }}
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <v-list-item
          v-else
          exact
          :to="item.link"
          :data-test-id="item.title.replaceAll(' ','-').toLowerCase()"
          color="primary"
          :disabled="!item.link && possuiLeads"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon v-if="!item.link">
            <v-chip class="ma-0" small color="default">Em breve</v-chip>
          </v-list-item-icon>
        </v-list-item>
        </div>
         <v-list-item>
         </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-dialog v-model="showModalConfirmacaoDados" persistent max-width="500">
      <v-card>
        <v-card-title>
          Confirmação de dados
        </v-card-title>
        <v-card-text>
          Olá {{ user.nome }}, para acessar é necessário fazer a validação do seu e-mail e telefone.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="pt-1" color="primary" @click="onClickConfirmModal()">
            VALIDAR
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </fragment>
</template>

<script>
import { debounce, isEmpty } from "lodash";
import ClickOutside from "vue-click-outside";
import { mapActions, mapState } from 'vuex';
import fotoPadrao from '@/assets/img/avatar.png';
import Cronometro from './leads/Cronometro.vue';
import relatoriosService from "@/services/relatoriosService";
import moment from "moment";

export default {
  name: 'AppHeader',
  components: { Cronometro },
  directives: {
    ClickOutside,
  },
  data() {
    return {
      drawer: false,
      drawerOptions: [
        {
          title: 'Início',
          icon: 'mdi-home',
          link: { name: 'areaLogada' },
        },
        {
          title: 'Vendas',
          icon: 'mdi-currency-usd',
          link: { name: 'areaLogada.propostas' },
          items: [
             {
                title: 'Criar Proposta Adesão',
                icon: 'mdi-plus-circle-outline',
                link: { name: 'areaLogada.criarProposta' },
             },
             {
                title: 'Criar Proposta PME',
                icon: 'mdi-plus-circle-outline',
                link: { name: 'areaLogada.PME' },
             },
             {
                title: 'Acompanhar Propostas Adesão',
                icon: 'mdi-file-document-outline',
                link: { name: 'areaLogada.propostas' },
             },
             {
                title: 'Acompanhar Propostas PME',
                icon: 'mdi-file-document-outline',
                link: { name: 'areaLogada.propostasPME' },
             },
             {
                title: 'Consultar Vigência',
                icon: 'mdi-calendar-month-outline',
                link: { name: 'areaLogada.vigencia' },
             },
             {
                title: 'Tabela de Vendas',
                icon: 'mdi-table-check',
                link: { name: 'areaLogada.tabelasVenda' },
             },
             {
                title: 'Envio de Boletos',
                icon: 'mdi-share-variant-outline',
                link: { name: 'areaLogada.envioBoleto' },
             },
             {
                title: 'Histórico de Alterações',
                icon: 'mdi-pillar',
                link: { name: 'areaLogada.historicoAlteracoes' },
             }
          ]
        },
        {
          title: 'Simulações',
          icon: 'mdi-file-document-edit-outline',
          link: { name: 'areaLogada.simulacoes' },
          items: [
            {
              title: 'Criar Simulação',
              icon: 'mdi-plus-circle-outline',
              link: { name: 'areaLogada.criarSimulacao' },
            },
            {
              title: 'Acompanhar Simulações',
              icon: 'mdi-file-document-outline',
              link: { name: 'areaLogada.simulacoes' },
            }
          ]
        },
        {
          title: 'Leads',
          icon: 'mdi-account-group',
          link: { name: 'areaLogada.lead' },
          items: [
            {
              title: 'Ver Leads',
              icon: 'mdi-account-plus',
              link: { name: 'areaLogada.lead' },
            },
            {
              title: 'Plantão de Leads',
              icon: 'mdi-account-tie',
              link: { name: 'areaLogada.plantaoLeads' },
            },
            {
              title: 'Calendário',
              icon: 'mdi-calendar-clock',
              link: { name: 'areaLogada.leads.calendario' },
            },
          ],
        },
        {
          title: "Meu Painel",
          icon: 'mdi-badge-account-horizontal-outline',
          link: { name: 'areaLogada.notificacoes' },
          items: [
            {
              title: 'Notificações',
              icon: 'mdi-bullhorn-outline',
              link: { name: 'areaLogada.notificacoes' },
            },
            {
              title: 'Visão Geral',
              icon: 'mdi-card-account-details-outline',
              link: { name: 'areaLogada.visaoGeral' },
            }
          ]
        },
        // { title: 'Minha conta', icon: 'mdi-account', link: { name: 'MinhaConta' } },
      ],
      possuiLeads: true,
      possuiPlantao: true,
      countAlertaLeads: 0,
      showModalConfirmacaoDados: false,
      showSubItems: false,
      notificacoesAlertas: 0,
      notificacoes: []
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user.profile,
      urlFotoPerfil: (state) => state.user.urlFotoPerfil,
      statusLeads: (state) => state.lead.statusLeads,
      plantaoLeads: (state) => state.lead.plantaoLeads,
    }),
    urlFoto() {
      return this.urlFotoPerfil.slice(0) || fotoPadrao;
    },
    options() {
      let drawerOptions = JSON.parse(JSON.stringify(this.drawerOptions));
      if (!this.possuiLeads && !this.possuiPlantao) {
        drawerOptions = drawerOptions.filter((item) => item.title !== 'Leads');
      }
      const { corretoras } = this.user;
      const PMEAdministrado = corretoras.find((item) => item.PMEAdministrado);
      if (!PMEAdministrado) {
        const vendasIndex = drawerOptions.findIndex((item) => item.title === 'Vendas');
        drawerOptions[vendasIndex].items = drawerOptions[vendasIndex].items.filter((item) => item.title !== 'Criar Proposta PME' && item.title !== 'Acompanhar Propostas PME');
      }
      return drawerOptions;
    },
  },
  created() {
    window.addEventListener("resize", debounce(this.onResize, 500));
    this.drawer = this.$vuetify.breakpoint.mdAndUp;
    this.possuiPlantaoCorretor();
  },
  async mounted() {
    try {
      this.getNotificacoes()
      this.countAlertaLeads = this.statusLeads && this.statusLeads.qtAlertasTratamento ? this.statusLeads.qtAlertasTratamento : 0;
      this.possuiLeads = !!this.statusLeads.possuiLeads;
    } catch (error) {
      this.$root.$snackBar.open({
        color: "error",
        message: "Erro ao carregar as notificações, tente novamente mais tarde",
      });
    } finally {
      await this.$refs.cronometro.buscarPlantoes();
    }
    setInterval(this.getNotificacoes, 15 * 6000);
  },
  destroyed() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    ...mapActions({
      logout: 'auth/logout',
    }),
    async logoutApp() {
      await this.logout();
      this.$router.push({ name: 'login' });
    },
    async possuiPlantaoCorretor() {
      this.possuiPlantao = false;
      if (this.plantaoLeads && this.plantaoLeads.length) {
        this.possuiPlantao = !!(this.plantaoLeads.filter((item) => ((item.status === 'Em andamento') && !item.pausado)) || []).length;
      }
    },
    async getNotificacoes() {
      if (!isEmpty(this.user)) {
        try {
          this.notificacoesAlertas = 0
          this.notificacoes = []
          const notificacoes = await relatoriosService.alteracoesPropostas();
          const notificacoesFiltradas = notificacoes.filter(n => n.dataAtualizacao >= this.lastNDays())
          notificacoesFiltradas.forEach((notificacao) => {
            notificacao.cor = this.getColor(notificacao.statusProposta);
            if (!notificacao.visto) {
              this.notificacoesAlertas++;
              this.notificacoes.push(notificacao)
            }
          });
        } catch (error) {
          this.$root.$snackBar.open({
            color: "error",
            message: error.message,
          });
        }
      }
    },
    getColor(status) {
      switch (status) {
      case "contrato_assinado":
      case "pronta_transmissao":
        return "blue";
      case "devolvida":
        return "deep-orange";
      case "implantada":
        return "green";
      case "finalizada":
        return "lime";
      default:
        return "grey";
      }
    },
    lastNDays() {
      var today = new Date();
      var priorDate = new Date(new Date().setDate(today.getDate() - 90))
      return moment(priorDate).format('DD/MM/YYYY')
    },
    async verProposta(internalId, guid, tipo) {
      this.editando = internalId;
      try {
        await this.limparNotificações([{ internalId, tipo }]);
        await this.getNotificacoes()
        this.$router.push({ name: 'areaLogada.dadosProposta', params: { guid } });
      } catch (error) {
        this.$root.$snackBar.open({
          color: 'error',
          message: error.message,
        });
        this.editando = null;
      }
    },
    async limparNotificações(notificacoes) {
      try {
        notificacoes.forEach(async (not) => {
          await relatoriosService.alteracaoVisualizada({ internalId: not.internalId, tipo: not.tipo });
        })
      } catch (error) {
        this.$root.$snackBar.open({
          color: 'error',
          message: error.message,
        });
      }
    },
    menuClick(link, child) {
      if (link === "areaLogada.lead") {
        if (child) this.$refs.cronometro.buscarPlantoes();
        else return;
      }
      this.checkConfirmacaoDados();
    },
    onClickX() {
      if (this.drawer && this.$vuetify.breakpoint.smAndDown) {
        this.$emit("closeShowMenu", this.drawer);
        this.drawer = !this.drawer;
      }
    },
    closeShowMenu() {
      if (!this.drawer && this.$vuetify.breakpoint.smAndDown) this.showSubItems = false;
      this.drawer = !this.drawer;
      this.$emit("closeShowMenu", this.drawer);
    },
    onResize(e) {
      if (e.isTrusted && !this.drawer && this.$vuetify.breakpoint.mdAndUp) {
        this.drawer = true;
        this.$emit("closeShowMenu", this.drawer);
      }
    },
    handleClickOutside() {
      if (this.drawer && this.$vuetify.breakpoint.smAndDown) this.$emit("closeShowMenu", this.drawer);
    },
    checkConfirmacaoDados() {
      const {
        flagConfirmacaoDados, tentativasConfirmacaoDados, tipoCorretor, subTipoCorretor,
      } = this.user;
      if (
        !flagConfirmacaoDados
        && tipoCorretor !== "Interno"
        && subTipoCorretor !== "PME"
        && subTipoCorretor !== "Atendimento"
        && tentativasConfirmacaoDados
        && tentativasConfirmacaoDados > 3
        && !this.showModalConfirmacaoDados
      ) {
        this.showModalConfirmacaoDados = true;
      }
    },
    onClickConfirmModal() {
      this.showModalConfirmacaoDados = false;
      this.$router.push({ name: 'areaLogada.confirmacaoDados' });
    },
  },
};
</script>

<style lang="scss">
// header.v-app-bar {
//   @media (min-width: 500px) {
//     background: transparent !important;
//   }
// }
.avatar-image {
  display: block;
  border-radius: 50% !important;
  border: 1px solid #fff !important;
  overflow: hidden;
  img {
    object-fit: cover;
  }
}
.bg-header {
  background: 'primary';
}
.btn-close {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 15px;
  right: 15px;
  background: 'primary';
  border-radius: 50% !important;
  padding: 2px;
  outline: none;
}
</style>
